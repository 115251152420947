import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/react-native-app-development/Anchors'
import ReactAppDevBenefits from '~/mock/services/react-native-app-development/ReactAppDevBenefits'
import ReactAppDevBusinesses from '~/mock/services/react-native-app-development/ReactAppDevBusinesses'
import ReactAppDevExpertise from '~/mock/services/react-native-app-development/ReactAppDevExpertise'
import ReactAppDevFAQ from '~/mock/services/react-native-app-development/ReactAppDevFAQ'
import ReactAppDevIndustries from '~/mock/services/react-native-app-development/ReactAppDevIndustries'
import ReactAppDevPreferences from '~/mock/services/react-native-app-development/ReactAppDevPreferences'
import ReactAppDevProcess from '~/mock/services/react-native-app-development/ReactAppDevProcess'
import ReactAppDevServices from '~/mock/services/react-native-app-development/ReactAppDevServices'
import ReactAppDevTabContent from '~/mock/services/react-native-app-development/ReactAppDevTabContent'
import ReactAppDevTabList from '~/mock/services/react-native-app-development/ReactAppDevTabList'
import ReactAppDevToolkit from '~/mock/services/react-native-app-development/ReactAppDevToolkit'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesToolkitSection from '~/views/Services/components/ServicesToolkitSection'
import useReactNativeAppDevelopmentStaticQuery from '~/views/Services/ReactNativeAppDevelopment/useReactNativeAppDevelopmentStaticQuery'

import * as containerStyles from './ReactNativeAppDevelopment.module.scss'

const ReactNativeAppDevelopment = () => {
  const query = useReactNativeAppDevelopmentStaticQuery()
  const reactAppDevTabContent = ReactAppDevTabContent()
  const clientImpact = getImage(fileToImageLikeData(query.clientImpact))

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="React Native App"
        title="Development Services"
        description="We create lightweight and stable apps delivering an excellent user experience with our professional React Native development services. Request a quote!"
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="React Native App Development Services"
        breadcrumbCurrentPath="/services/react-native-app-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.reactAppDevServicesIconSection}
      >
        <HeadSection
          position="center"
          title="React Native app development services we offer"
          description={[
            'Codica is a leading software development company that offers a range of services for React Native app development. Our specialists are tailored to meet the unique requirements of each client, with a focus on delivering high-quality, scalable, and user-friendly apps.',
            <span className="mb-2 d-block" key="keyServicesSection" />,
            'The Codica team follows an agile development methodology and maintains transparent communication with clients throughout development, ensuring a collaborative and successful partnership.',
          ]}
          descriptionLarge
        />
        <Service3DIconCard dataContent={ReactAppDevServices} />
      </section>

      <section
        id="benefits"
        className={containerStyles.reactAppDevCardBorderIconSection}
      >
        <HeadSection
          position="center"
          title="Benefits of app development with React Native"
          description="Basically, the main advantages of React Native mobile app development include cross-platform development, native-like performance, and cost efficiency. Also, our React Native developers provide several other essential development benefits for your business."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={ReactAppDevBenefits} />
        <SubtractBlock />
      </section>

      <CallToAction
        title="Want to create a new mobile app?"
        subtitle="Our expert team will deliver you a reliable solution."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="business-type"
        className={containerStyles.reactAppDevBusinessSection}
      >
        <HeadSection
          position="center"
          title="Business types that trust our React development services"
          description="Due to its cost-effectiveness, flexibility, scalability, and our specialists' professional skills, Codica’s React Native app development services are trusted by a wide range of businesses, including startups, mid-scale companies, and enterprises."
          titleLarge
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={ReactAppDevBusinesses} />
      </section>

      <section
        className={containerStyles.reactAppDevIndustriesSection}
        id="industries"
      >
        <HeadSection
          position="center"
          title="Domain expertise you can rely on"
          description="Our experience developing software for specific fields has enabled us to grow our in-house expertise, which we are open to sharing with our clients. We offer consultations on every phase of software development and make reliable and modern products."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={ReactAppDevIndustries} />
      </section>

      <section
        id="process"
        className={containerStyles.reactAppDevProcessSection}
      >
        <HeadSection
          position="center"
          title="Our React Native app development process"
          description="Working with Codica’s experienced React Native development team can help ensure a smooth, well-structured, and successful development process for your app idea."
          titleLarge
          descriptionLarge
        />
        <ServicesProcess dataContent={ReactAppDevProcess} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.reactAppDevTechStackSection}
      >
        <HeadSection
          position="center"
          title="The top technology stack that we choose"
          description="Our choice of the technology stack for a React Native app development depends on various factors such as the specific project requirements and preferences, scalability needs, and timeline. Regardless, our team collected some commonly used and popular technology stack components for React Native app development."
          titleLarge
          descriptionLarge
        />
        <ServicesToolkitSection dataContent={ReactAppDevToolkit} />
      </section>

      <section className={containerStyles.reactAppDevPreferencesSection}>
        <HeadSection
          position="center"
          title="Benefits to create your React Native product with Codica"
          description="We are a well-established and reputable software development company with expertise in building custom mobile applications. So, our specialists develop innovative and high-quality solutions catering to diverse industries, including e-commerce, healthcare, finance, travel, and more."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={ReactAppDevPreferences} />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.reactAppDevProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <CallToAction
        title="Ready to try the power of React Native?"
        subtitle="Our experts will deliver you an excellent app."
        classNameForGA="servicesCTA_2"
        linkName="Contact us"
        link="/contacts/"
      />

      <section className={containerStyles.reactAppDevCaseSection} id="cases">
        <HeadSection position="left" title="Our portfolio" />
        <DiscoverWorksTabs
          dataTabList={ReactAppDevTabList}
          dataContent={reactAppDevTabContent}
        />
      </section>

      <section className={containerStyles.reactAppDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Codica is a reliable React Native app development company"
          description={
            <>
              As a
              <Link
                to="/services/mobile-app-development/"
                className="mx5"
                key="mobileAppDevLink"
              >
                mobile app development company
              </Link>
              we prioritize your unique requirements and business goals to
              deliver tailor-made solutions that exceed your expectations. Our
              team follows industry best practices and conducts rigorous testing
              to ensure the highest quality standards and bug-free apps. We
              deliver React Native application development services for years to
              make our clients happy.
            </>
          }
          titleLarge
          descriptionLarge
        />
        <ServicesDevExpertiseCompany dataContent={ReactAppDevExpertise} />
      </section>

      <section className={containerStyles.reactAppDevClientsSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="Genuine feedback from our clients is precious to us and inspires further progress. We invite you to explore the transformative business experiences of people who entrusted their projects to Codica."
          descriptionLarge
        />

        <div className="container container-md">
          <ClientVideoQuotes
            name="Richard Gunter"
            location="Israel"
            position="Founder of Impact Personal Training"
            image={clientImpact}
            quote={[
              {
                text: '“Coming from a gym environment, I had no idea how to build, create, and develop an app. Everything I needed to do was explained to me in layman’s terms. I was always up to date with where they were at on the project itself, which I found really helpful.”',
              },
            ]}
            link="fitness-progressive-web-application"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s develop an outstanding application together!"
          widthTitle="700px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our custom app specialists will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={ReactAppDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default ReactNativeAppDevelopment
