// extracted by mini-css-extract-plugin
export var reactAppDevBusinessSection = "t_cL";
export var reactAppDevCardBorderIconSection = "t_cK";
export var reactAppDevCaseSection = "t_cS";
export var reactAppDevClientsSection = "t_cV";
export var reactAppDevExpertiseSection = "t_cT";
export var reactAppDevIndustriesSection = "t_cM";
export var reactAppDevPreferencesSection = "t_cQ";
export var reactAppDevProcessSection = "t_cN";
export var reactAppDevProjLogoSection = "t_cR";
export var reactAppDevServicesIconSection = "t_cJ";
export var reactAppDevTechStackSection = "t_cP";